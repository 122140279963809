// Styles
$font-primary: 'Montserrat', sans-serif;
$font-secondary: 'Inria Serif', serif;
$transition: .25s cubic-bezier(.75, 0, .25, 1);
$transition-quick: .15s ease-in-out;
$border-radius: 5px;
$shadow: 0 1px 3px rgba(0, 0, 0, .15);
$shadow-hover: 0 3px 6px rgba(0, 0, 0, .15);

// Colors
$color-primary: #17853D;
$color-primary-dark: darken($color-primary, 5%);
$color-primary-darker: darken($color-primary, 10%);
$color-primary-light: lighten($color-primary, 20%);
$color-primary-50: rgba($color-primary, .5);
$color-secondary: #0DB8A9;
$color-secondary-dark: darken($color-secondary, 20%);
$color-secondary-light: lighten($color-secondary, 20%);
$color-tertiary: #f6e9dc;
$color-tertiary-dark: darken($color-tertiary, 20%);
$color-tertiary-light: lighten($color-tertiary, 10%);
$color-grey: #ccc;
$color-grey-dark: #444;
$color-grey-light: #f2f2f2;
