@import url('https://fonts.googleapis.com/css?family=Inria+Serif:300,300i,400,400i,700,700i|Montserrat:400,400i,700,700i&display=swap');
@import 'utilities/variables';
@import 'utilities/mixins';
@import 'modules/modules';
@import 'modules/grid';
@import 'modules/type';
@import 'modules/colors';
@import 'styles/typography';
@import 'patterns/banners';
@import 'patterns/header';
@import 'patterns/footer';
@import 'patterns/logo';
@import 'layout';

html {
  scroll-behavior: smooth;
}
